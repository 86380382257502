<!--
 * @Author: your name
 * @Date: 2021-01-19 11:17:27
 * @LastEditTime: 2021-02-03 11:06:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\terms\index.vue
-->
<template>
  <div class="theallmsg">
    <van-nav-bar title="保险流程" left-arrow @click-left="onClickLeft" />
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="保障内容" name="first">
        <div class="firstclass">
          <div class="baoz">萝卜招聘保-保障您公司的招聘成本！</div>
          <div class="baoz2">当下情形发生时，您可以享受招聘成本赔付</div>
          <div class="baoz3">
            <div>1</div>
            <div>员工试用期主动离职</div>
          </div>
          <div class="baoz4">
            试用期未到期员工主动解约离职，造成贵司前期简历成本，背调成本全部或部分损失，可申请赔付；
          </div>
          <div class="baoz3">
            <div>2</div>
            <div>员工试用期被辞退</div>
          </div>
          <div class="baoz4">
            试用期未到期员工表现不佳被公司主动辞退，造成贵司前期简历成本，背调成本全部或部分损失，可申请赔付；
          </div>
          <div class="baoz3">
            <div>3</div>
            <div>员工试用期健康意外无法继续工作</div>
          </div>
          <div class="baoz4">
            试用期未到期员工健康出现意外，无法继续工作，造成贵司前期简历成本，背调成本全部或部分损失，可申请赔付；
          </div>
          <div class="baoz3">
            <div>4</div>
            <div>员工试用期不可抗力无法工作</div>
          </div>
          <div class="baoz4">
            试用期未到期员工因为不可抗力导致无法继续工作，造成贵司前期简历成本，背调成本全部或部分损失，可申请赔付；
          </div>
          <img class="firstimg" src="../../assets/bao.png" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="发起流程" name="second">
        <div class="secondclass">
          <div class="lingqu1">萝卜猎手背调发起页面签约</div>
          <div class="lingqu2">
            如您在萝卜猎手上针对同一候选人发起背调，或下载简历并发起背调，招聘补偿保险领取流程
          </div>
          <div class="process">
            <div class="proliu">
              <div class="liuclass">
                <div>
                  <div>1</div>
                  <p>进入背调授权发起详情页</p>
                </div>
                <div>
                  <div>2</div>
                  <p>选择背调产品</p>
                </div>
                <div>
                  <div>3</div>
                  <p>确认支付方</p>
                </div>
                <div>
                  <div>4</div>
                  <p>勾选详情页补偿保险单选框</p>
                </div>
                <div>
                  <div>5</div>
                  <p>发起背调授权，且候选人完成背调</p>
                </div>
                <div>
                  <div>6</div>
                  <p>保险领取完毕且生效</p>
                </div>
              </div>
            </div>
          </div>
          <img src="../../assets/lingqu.png" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="理赔流程" name="third">
        <div class="lipeiclass">
          <div class="lipei1">
            发生候选人离职情况后，合众保险提供 7*24 小时理赔受理服务
          </div>
          <div class="baoz3 baozes">
            <div>1</div>
            <div>第一步</div>
          </div>
          <div class="baoz4">
            当公司出现招聘成本损失时，联系平台客服或进入理赔页面进行理赔；
          </div>
          <div class="baoz3 baozes">
            <div>2</div>
            <div>第二步</div>
          </div>
          <div class="baoz4">
            检查该员工是否在保险期内是否完成了入职和离职的授权操作，确定是否属于保险责任范围；
          </div>
          <div class="baoz3 baozes">
            <div>3</div>
            <div>第三步</div>
          </div>
          <div class="baoz4">
            公司准备索赔单证至保险公司，索赔单证（含扫描件） 如一下所示；
            <div class="mm2">1、【抽检】离职员工入职证明（加盖企业公章）；</div>
            <div class="mm1">2、【抽检】离职员工离职证明（加盖企业公章）；</div>
            <div class="mm1">3、【抽检】离职员工身份证复印件；</div>
            <div>4、【抽检】离职员工试用期社保证明（加盖公章）；</div>
          </div>
          <div class="baoz3 baozes">
            <div>4</div>
            <div>第四步</div>
          </div>
          <div class="baoz4">
            索赔单证齐全，保险公司随机进行抽检后，进行理赔，按约定支付模式支付赔偿额至约定账户。
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="常见问题" name="fourth">
        <div class="wenticlass">
          <div class="wenti1">
            招聘成本补偿保险相关问题咨询：萝卜猎手官方客服
          </div>
          <div class="wenti2">（工作时间：09:00-21:00)</div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">
            申请理赔的时候如果已经操作了员工入职授权，但是离职员工不配合进行离职授权，是否能够申请理赔。
          </div>
          <img class="wentiimg" src="../../assets/da.png" />
          <div class="wenti3">
            1.
            在保障期内，且已经操作了员工入职授权，但未操作离职授权的，在联系理赔客服后，且能提供完整员工离职证明的，可以理赔。
          </div>
          <div class="wenti3">
            2.
            申请理赔时间超出保障期的，但已经操作完成员工入职授权，且未操作离职授权的，在联系理赔客服后，且能提供完整员工离职证明的，可以理赔。
          </div>
          <div class="wenti3">
            3.
            申请理赔时间超出保障期的，且未操作员工入职授权，也未操作离职授权的，无法进行在线理赔申请，需联系客服进行线下理赔申请。
          </div>
          <div class="henggang"></div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">关于保险期限的时间？</div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">
            针对单个候选人获取背景调查报告当天开始 至
            90天内，以背景调查报告生成日期为准；
          </div>
          <div class="wenti3">
            在保险期间內企业需在萝卜猎手平台PC端对该员工进行入离职操作，保险保障随试用期入职合同/协议到期而终止保险责任；试用期合同于90天期限的，保险到期后，保障随保险期间终止而结束；保险期间结束后试用期合同持续有效的员工不可继续购买本保险。
          </div>
          <div class="henggang"></div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">
            什么情况下因员工离职导致企业招聘成本损失的可以理赔？
          </div>
          <img class="wentiimg" src="../../assets/da.png" />
          <div class="wenti3">
            无论任何原因导致员工无法工作的，能提供相关证明的，因此导致的企业招聘成本损失属于赔偿范围。
          </div>
          <div class="henggang"></div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">
            试用期员工发生意外或疾病治疗导致无法工作，离职造成的企业招聘成本损失可以理赔吗？
          </div>
          <img class="wentiimg" src="../../assets/da.png" />
          <div class="wenti3">
            可以理赔，无论任何原因导致员工无法工作的，能提供相关证明的，因此导致的企业招聘成本损失属于赔偿范围。
          </div>
          <div class="henggang"></div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">
            因为企业自身的过失造成员工离职，因此导致的企业招聘成本损失，可以理赔吗？
          </div>
          <img class="wentiimg" src="../../assets/da.png" />
          <div class="wenti3">
            可以理赔，被保险人在保险期间内，因自身的过失造成员工无法工作或离职的，只要90内保障期内，在萝卜猎手平台系统上操作完成入职和离职动作的，因离职导致的企业招聘成本损失的，属于保险赔偿范围。
          </div>
          <div class="henggang"></div>
          <img class="wentiimg" src="../../assets/wen.png" />
          <div class="wenti3">如何获得保单？</div>
          <img class="wentiimg" src="../../assets/da.png" />
          <div class="wenti3">
            投保成功后，在萝卜猎手平台个人中心页面“联系客服”查询即可，也可登陆保险公司官网保单查询进行保单的查询、验真、下载。
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="理赔示例" name="five">
        <div class="shiliclass">
          <div class="shili">
            <div class="shili2">
              <div>赔付</div>
              <div>示例一</div>
            </div>
            <div class="shili3">
              试用期内
              <div></div>
              员工辞职
              <div></div>
              招聘成本损失
            </div>
          </div>
          <div class="process">
            <div class="proliu">
              <div class="liuclass">
                <div>
                  <div id="twoyuan">1</div>
                  <p>
                    A公司通过萝卜猎手下载10份简历并对其中5人发起背调后邀请甲候选人入职。
                  </p>
                </div>
                <div>
                  <div id="twoyuan">2</div>
                  <p>
                    A公司，并在背调授权时勾选了招聘成本损失保险,总计花费（背调400元，相关简历800元），入职一个月后，甲员工的父母病重，需回老家照顾父母，故申请离职，导致公司损失招聘成本1500元。A公司在萝卜猎手平台上操作完甲员工的离职后，随即联系保险公司进入理赔流程，在提供了相关证明材料后，满足理赔条件，保险公司于
                    1 个工作日后将A公司损失的 1200 元的招聘成本在线支付给A公司。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>

      <!-------------------------------------------------------->

      <el-tab-pane label="条款" name="sex">
        <div class="mainclass">
          <div class="themsgclass">
            <div>保险基础</div>
            <div>萝卜猎手高级认证客户与萝卜猎手平台产生背景调查服务订单</div>
          </div>
          <div class="themsgclass tmc">
            <div>投保人</div>
            <div>萝卜猎手</div>
          </div>
          <div class="themsgclass">
            <div>被保险人</div>
            <div>萝卜猎手平台注册并完成高级认证的企业客户</div>
          </div>
          <div class="menuclass">
            <div>1</div>
            <div>保障项目</div>
          </div>
          <div class="themoclass">当招聘费用损失 &lt;= 100元</div>
          <div class="bapc">
            <div>
              <div class="bttop">保额</div>
              <div class="btbottom">保额为该候选人招聘 成本总计</div>
            </div>
            <div>
              <div class="bttop">保单赔偿上限额</div>
              <div class="btbottom">保单赔偿上限为300元</div>
            </div>
            <div>
              <div class="bttop">月保费/费率</div>
              <div class="btbottom">3元（定额）</div>
            </div>
          </div>
          <div class="hxclass"></div>
          <div class="menuclass">
            <div>2</div>
            <div>保障项目</div>
          </div>
          <div class="themoclass">当招聘费用损失 &gt;= 100元</div>
          <div class="bapc">
            <div>
              <div class="bttop">保额</div>
              <div class="btbottom">保额为该候选人招聘 成本总计</div>
            </div>
            <div>
              <div class="bttop">保单赔偿上限额</div>
              <div class="btbottom">上不封顶</div>
            </div>
            <div>
              <div class="bttop">月保费/费率</div>
              <div class="btbottom">3元(定额)+3%</div>
            </div>
          </div>
          <div class="hxclass"></div>
          <div class="bqcla">保险期间</div>
          <div class="zdclass">
            针对单个候选人获取背景调查报告当天开始 至
            90天内，以背景调查报告生成日期为准
          </div>
          <div class="bqcla">特别约定</div>
          <div class="bottommsg">
            <div>
              1、招聘成本损失指企业客户在基于高级认证的前提下，在萝卜猎手针对招聘候选人花费的成本，其中包括背景调查费用及简历下载费用；
            </div>
            <div>
              2、生成背景调查报告当日（需勾选并确认授权发起页面的投保选项）开始投保，生成背景调查报告后无法继续投保；
            </div>
            <div>3、投保过风险保障方案的订单在明细页面有已投保标识；</div>
            <div>
              4、被保险的候选人工作情况变动，以萝卜猎手工作台“入职”“离职”操作时间的系统显示时间为准，且“入职”和“离职”时间间隔大于3天；
            </div>
            <div>
              5、在保险标的订单的候选人生成背景调查报告开始后的90天内，被保险的候选人的职业档案内有过明确显示的“入职”“离职”标志，且“离职”时间处于90天以内的，即可达到起赔标准。（90天内，含第90天）；
            </div>
            <div>
              6、理赔时萝卜猎手客服有权要求企业客户方提供该员工的官方入离职书面证明等一切证明工作记录的材料作为理赔依据；
            </div>
            <div>
              7、唯一理赔受理页面为萝卜猎手官方系统内（含微信公众号指定页面）；
            </div>
            <div>
              8、理赔成功后，保险公司委托萝卜猎手官方直接将余额划拨到被保险人注册客户账户内；
            </div>
            <div>
              9、提交理赔后，最长受理时间为7个工作日。如理赔材料不足，保险公司将委托萝卜猎手客服联系被保险人或被保险人指定联系人进行材料补充；
            </div>
            <div>10、其他详见保险条款。</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: "萝卜招聘损失保",
      activeName: "first",
    };
  },
  components: {},
  mounted() {},
  methods: {
    onClickLeft() {
      this.$parent.fatherMethod();
    },
    handleClick(tab, event) {
      
    },
  },
};
</script>
<style scoped>
.theallmsg>>>.el-tabs--border-card{
  border:none;
}
.theallmsg>>>.el-tabs--border-card>.el-tabs__header{
  background-color: #ffffff;
  border-bottom: none;
}
/* .el-tabs__item >>> .is-active{
  background-color:#6B9EFF;
} */
.el-tabs >>> .el-tabs__header {
  padding: 0 0.3rem;
}
#twoyuan {
  min-width: 0.5rem;
}
.shili3 > div {
  width: 0.07rem;
  height: 0.07rem;
  background: #ffffff;
  margin: 0 0.2rem;
  border-radius: 50%;
}
.shili3 {
  display: flex;
  align-items: center;
}
.shili3 {
  font-size: 0.24rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
}
.shili2 > div:nth-child(2) {
  padding: 0.14rem 0;
  padding-top: 0.18rem;
}
.shili2 > div:first-child {
  padding: 0.14rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-left-color: #ffffff;
  margin-right: 0.3rem;
}
.shili2 {
  display: flex;
  font-size: 0.3rem;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.42rem;
}
.shiliclass {
  padding: 0 0.3rem;
  padding-bottom: 0.3rem;
}
.shili {
  background-image: url("../../assets/peifu.png");
  background-size: 100% 100%;
  padding: 0.37rem;
  padding-bottom: 0.66rem;
}
.henggang {
  height: 1px;
  background-color: #d4d4d4;
  margin: 0.4rem 0;
  margin-bottom: 0.1rem;
}
.wentiimg {
  width: 0.57rem;
  height: 0.57rem;
  margin-top: 0.31rem;
  margin-bottom: 0.15rem;
}
.wenti3 {
  font-size: 0.26rem;
  font-weight: 500;
  color: #656565;
  line-height: 0.4rem;
}
.wenti2 {
  font-size: 0.28rem;
  font-weight: 600;
  color: #222222;
  line-height: 0.4rem;
  margin-top: 0.08rem;
}
.wenti1 {
  font-size: 0.28rem;
  font-weight: 600;
  color: #222222;
  line-height: 0.4rem;
}
.wenticlass {
  padding: 0 0.3rem;
  padding-bottom: 0.3rem;
}
.mm1 {
  margin: 0.3rem 0;
}
.mm2 {
  margin-top: 0.3rem;
}
.baozes {
  width: 30%;
}
.lipei1 {
  font-size: 0.38rem;
  font-weight: 600;
  color: #f55d10;
  line-height: 0.53rem;
}
.lipeiclass {
  padding: 0 0.3rem;
  padding-bottom: 0.3rem;
}
.secondclass img {
  width: 100%;
  margin-top: 0.5rem;
}
.liuclass div {
  margin-bottom: 0.79rem;
  display: flex;
  margin-left: -0.15rem;
}
.liuclass div div:first-child {
  background-color: #f55814;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.36rem;
  display: inline-block;
  text-align: center;
  line-height: 0.5rem;
  font-weight: 500;
  color: #ffffff;
  margin-right: 0.34rem;
  border-radius: 50%;
  margin-bottom: 0;
}
.liuclass div p {
  font-size: 0.26rem;
  color: #656565;
  font-weight: 500;
  line-height: 0.5rem;
}
.liuclass div:last-child {
  margin-bottom: 0;
}
.proliu {
  display: flex;
  align-items: center;
  border-left-color: #f55814;
  border-left-style: solid;
  border-left-width: 0.1rem;
  margin-top: 0.69rem;
  margin-left: 0.2rem;
}
.process > p {
  color: #222222;
  font-weight: 500;
  font-size: 0.34rem;
}
.lingqu2 {
  font-size: 0.26rem;
  font-weight: 500;
  color: #656565;
  line-height: 0.4rem;
  margin-top: 0.1rem;
}
.lingqu1 {
  font-size: 0.28rem;
  font-weight: 600;
  color: #222222;
  line-height: 0.4rem;
}
.secondclass {
  padding: 0 0.36rem;
}
.firstimg {
  width: 100%;
}
.baoz4 {
  font-size: 0.26rem;
  color: #656565;
  line-height: 0.4rem;
  margin: 0 0.16rem;
  margin-top: 0.2rem;
}
.baoz3 > div:nth-child(2) {
  font-size: 0.28rem;
  color: #ffffff;
}
.baoz3 > div:first-child {
  width: 0.46rem;
  height: 0.46rem;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222222;
  font-size: 0.26rem;
  line-height: 0.4rem;
  margin-right: 0.15rem;
}
.baoz3 {
  background: linear-gradient(90deg, #f5a310 0%, #ffcc00 100%);
  padding: 0.12rem 0.15rem;
  border-radius: 0.35rem;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.baoz2 {
  font-size: 0.26rem;
  line-height: 0.48rem;
  color: #656565;
  margin-top: 0.06rem;
}
.firstclass {
  padding: 0 0.25rem;
}
.baoz {
  font-size: 0.38rem;
  font-weight: 500;
  color: #f55d10;
  line-height: 0.53rem;
}
.bottommsg {
  font-size: 0.28rem;
  line-height: 0.45rem;
  margin-top: 0.11rem;
}
.zdclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin-top: 0.14rem;
  margin-bottom: 0.58rem;
}
.bqcla {
  font-size: 0.3rem;
  line-height: 0.3rem;
  font-weight: bold;
}
.hxclass {
  height: 1.5px;
  background-color: #cbcbcb;
  margin-top: 0.26rem;
  margin-bottom: 0.55rem;
}
.btbottom {
  font-size: 0.24rem;
  line-height: 0.4rem;
  color: #5c5c5c;
}
.bttop {
  font-size: 0.28rem;
  line-height: 0.3rem;
  margin-bottom: 0.14rem;
  font-weight: bold;
}
.bapc {
  display: flex;
  justify-content: space-between;
}
.bapc > div {
  width: 30%;
}
.themoclass {
  font-size: 0.24rem;
  line-height: 0.4rem;
  color: #5c5c5c;
  margin-bottom: 0.23rem;
}
.menuclass {
  display: flex;
  align-items: center;
  margin-top: 0.55rem;
  margin-bottom: 0.15rem;
}
.menuclass > div:nth-child(2) {
  font-size: 0.3rem;
  line-height: 0.3rem;
  font-weight: bold;
  margin-left: 0.13rem;
}
.menuclass > div:first-child {
  width: 0.33rem;
  height: 0.33rem;
  font-size: 0.24rem;
  line-height: 0.33rem;
  border: 1px solid #f55613;
  color: #f55613;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainclass {
  margin: 0.39rem 0.29rem;
}
.themsgclass > div:first-child {
  font-size: 0.3rem;
  line-height: 0.3rem;
  color: #333333;
  font-weight: bold;
}
.themsgclass > div:nth-child(2) {
  font-size: 0.24rem;
  line-height: 0.4rem;
  color: #5c5c5c;
  margin-top: 0.15rem;
}
.theallmsg {
  background-color: #ffffff;
  padding-bottom: 0.5rem;
}
.tmc {
  margin: 0.36rem 0;
}
</style>
